<script>
import axios from "axios";
export default {
  data() {
    return {
      userId: this.$route.params.id,
      alias: this.$route.params.alias,
      name: "",
      thread_id: null,
      messages: null,
      token: localStorage.getItem("authtoken"),
      messagesLoading: true,
      recipient: {},
      unread: false,
      meta: {},
      translate_mode : false,
    };
  },
  created() {
    //reload the page every time the route changes
    this.$watch(
      () => this.$route.params.id,
      (newId) => {
        this.messagesLoading = true;
        this.userId = newId;
        this.messages = null;
        this.alias = this.$route.params.alias;
        this.getparticipantData(this.alias, this.userId);
      }
    );
    this.$watch(
      () => this.$route.params.alias,
      (newAlias) => {
        this.userId = this.$route.params.id;
        this.messages = null;
        this.alias = newAlias;
        this.getparticipantData(this.alias, this.userId);
      }
    );
    this.getparticipantData(this.alias, this.userId);
  },
  computed: {
    threadsUsers() {
      return this.$store.getters.threadsUsers;
    },
    currentChat() {
      return this.$store.getters.currentChat;
    },
  },
  mounted() {},
  methods: {
    getparticipantData(alias, userId) {
      this.messagesLoading = true;
      const chat = this.$store.getters.chats(userId);
      console.log('chat - ' , chat)
      if (chat && chat.threadId) {
        this.$store.commit("setCurrentChat", {
              ...this.currentChat,
              resources: { recipient: chat.recipient },
            });
        // console.log('got chat in chat index- ',chat.messages);
        this.messages = [];
        this.messages = chat.messages;
        this.meta = chat.meta;
        this.recipient = chat.recipient;
        this.userId = chat.userId;
        this.thread_id = chat.threadId;
        this.messagesLoading = false;
        console.log(chat.translate_mode)
        this.translate_mode = chat.translate_mode;
        this.readMessages(chat.threadId);
      } else {
        let self = this;
        self.name = "";
        axios
          .get(
            `${this.$config.APIURL}/api/messenger/privates/recipient/${alias}/${userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then(({ data }) => {
            let self = this;
            self.name = data.recipient.name;
            self.recipient = data.recipient;
            self.thread_id = data.thread_id;
            this.$store.commit("setCurrentChat", {
              ...this.currentChat,
              resources: { recipient: { avatar: data.recipient.avatar } },
            });
            if (self.thread_id) {
              self.getMessages(self.thread_id);
              self.$store.commit("setThreadId", self.thread_id);
            } else {
              self.messages = [];
              self.messagesLoading = false;
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    },
    readMessages(thread_id) {
      let thread = this.threadsUsers && this.threadsUsers.length ? this.threadsUsers.find((th) => th.id == thread_id) : null;
      if(thread && (thread.unread || thread.unread_count > 0)) {
        axios
          .get(
            `${this.$config.APIURL}/api/messenger/threads/${thread_id}/mark-read`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then(() => {
            let users = this.threadsUsers;
            if (users && users.length) {
              users = users.map((user) => {
                if (user.id == thread_id) {
                  user.unread = false;
                  user.unread_count = 0;
                }
                return user;
              });
              this.$store.commit("setThreadsUsers", users);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    getMessages(thread_id) {
        let self = this;
        fetch(
          `${this.$config.APIURL}/api/messenger/threads/${thread_id}/load`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            self.readMessages(thread_id);
            self.messages = data.resources.messages.data;
            self.translate_mode = data.translate_mode == 1;
            console.log(self.translate_mode);
            self.meta = data.resources.messages.meta;
            self.unread = data.unread;
            self.messages = self.messages.reverse();
            self.$store.commit("setChats", {
              userId: self.userId,
              threadId: thread_id,
              messages: data.resources.messages.data,
              meta: data.resources.messages.meta,
              recipient : self.recipient,
              translate_mode : data.translate_mode == 1
            });
            self.messagesLoading = false;
            if (data.options && data.options.awaiting_my_approval) {
              self.approvemessage(thread_id);
            }
          })
          .catch((error) => {
            self.messagesLoading = false;
            console.error("Error:", error);
          });
    },
    approvemessage(thread_id) {
      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${thread_id}/approval`,
          {
            approve: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    addNewThread(thread) {
      this.$store.commit("setThreadId", thread.id);
      this.thread_id = thread.id;
    },
  },
};
</script>

<template>
  <div class="content main_content" style="overflow: hidden">
    <!-- Left Sidebar Menu -->
    <!-- <div class="sidebar-menu">
      <div class="logo">
        <router-link to="/index" class="logo-normal"
          ><img src="@/assets/img/logo.svg" alt="Logo"
        /></router-link>
      </div>
      <div class="menu-wrap">
        <div class="main-menu">
          <ul class="nav">
            <a-tooltip placement="right">
              <template #title>Chats</template>
              <li>
                <router-link
                  to="/index"
                  class="active"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-menu"
                >
                  <i class="ti ti-message-2-heart"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Contacts</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#contact-menu">
                  <i class="ti ti-user-shield"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Groups</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#group-menu">
                  <i class="ti ti-users-group"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Status</template>
              <li>
                <router-link to="/status">
                  <i class="ti ti-circle-dot"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Calls</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#call-menu">
                  <i class="ti ti-phone-call"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Profile</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#profile-menu">
                  <i class="ti ti-user-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Settings</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#setting-menu">
                  <i class="ti ti-settings"></i>
                </a>
              </li>
            </a-tooltip>
          </ul>
        </div>
        <profile-menu></profile-menu>
      </div>
    </div> -->
    <!-- /Left Sidebar Menu -->

    <!-- sidebar group -->
    <div class="sidebar-group">
      <div class="tab-content">
        <div class="tab-pane fade active show" id="chat-menu">
          <!-- Chats sidebar -->
          <index-sidebar></index-sidebar>
          <!-- / Chats sidebar -->
        </div>

        <!-- Contact -->
        <div class="tab-pane fade" id="contact-menu">
          <!-- Chats sidebar -->
          <chat-sidebar></chat-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Contact -->

        <!-- Group -->
        <div class="tab-pane fade" id="group-menu">
          <!-- Chats sidebar -->
          <group-sidebar></group-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Group -->

        <!-- Profile -->
        <div class="tab-pane fade" id="profile-menu">
          <!-- Profile sidebar -->
          <profile-sidebar></profile-sidebar>
          <!-- / Profile sidebar -->
        </div>
        <!-- /Profile -->

        <!-- Calls -->
        <div class="tab-pane fade" id="call-menu">
          <!-- Call sidebar -->
          <calls-sidebar></calls-sidebar>
          <!-- / Call sidebar -->
        </div>
        <!-- /Calls -->

        <!-- Settings -->
        <div class="tab-pane fade" id="setting-menu">
          <!-- Profile sidebar -->
          <settings-sidebar></settings-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Settings -->
      </div>
    </div>
    <!-- /Sidebar group -->

    <!-- Chat -->

    <chat-content
      :name="name"
      :alias="alias"
      :id="userId"
      :thread_id="thread_id"
      :messages="messages"
      :messagesLoading="messagesLoading"
      :unread="unread"
      :initialMeta="meta"
      @threadAdded="addNewThread"
      :translate_mode="translate_mode"
    ></chat-content>
    <!-- /Chat -->
    <contact-info :recipient="recipient"></contact-info>
    <fav-info></fav-info>
    <modal-index></modal-index>
  </div>
</template>
