<script>
import axios from "axios";
import { ref, onMounted, watch } from "vue";

export default {
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  setup() {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const token = localStorage.getItem("token");
    const isDarkMode = ref(false);
    const languages = ref([]);
    const showSettings = ref(false);
    const userLanguage = ref(user.language_id ? user.language_id : 0);
    const loading = ref(false);
    const toggleDarkMode = () => {
      if (isDarkMode.value) {
        document.body.classList.remove("darkmode");
        localStorage.setItem("darkMode", null);
      } else {
        document.body.classList.add("darkmode");
        localStorage.setItem("darkMode", "enabled");
      }
      isDarkMode.value = !isDarkMode.value;
      window.dispatchEvent(
        new CustomEvent("theme-changed", {
          detail: {
            message: isDarkMode.value,
          },
        })
      );
    };

    const initializeDarkMode = () => {
      const darkMode = localStorage.getItem("darkMode");
      isDarkMode.value = darkMode === "enabled";
    };

    const fetchLanguages = async () => {
      axios
        .get(`https://api.messenger.ooak.jp/api/v1/languages`)
        .then((res) => {
          languages.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const changeLanguage = async () => {
      loading.value = true;
      axios
        .put(
          `https://api.messenger.ooak.jp/api/v1/language`,
          { language_id: userLanguage.value },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(() => {
          const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
          if(user) {
            user.language_id = userLanguage.value,
            user.language = languages.value.find((lang) => lang.id == userLanguage.value)?.code;
            localStorage.setItem('user' , JSON.stringify(user));
          }
          showSettings.value = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      initializeDarkMode();
      fetchLanguages();
    });

    watch(isDarkMode, (newVal) => {
      if (newVal) {
        document.body.classList.add("darkmode");
      } else {
        document.body.classList.remove("darkmode");
      }
    });

    return {
      isDarkMode,
      toggleDarkMode,
      languages,
      changeLanguage,
      showSettings,
      userLanguage,
      user,
      loading,
    };
  },
};
</script>

<template>
  <div class="profile-menu" style="z-index: 9999">
    <div
      v-if="showSettings"
      @click="cancelSettings"
      class="position-fixed"
      style="width: 100vw; height: 100vh; top: 0; left: 0"
    >
      <div
        class="position-absolute p-3 d-block rounded-3"
        :style="{ backgroundColor: isDarkMode ? '#303030' : '#fff' }"
        style="
          width: 100%;
          min-width: 300px;
          max-width: 600px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 9999;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        "
      >
        <h4 class="w-100 mb-3">Change prefered translation</h4>
        <div>
          <select
            v-model="userLanguage"
            name="language-select"
            class="form-select"
          >
            <option v-for="lang in languages" :key="lang.id" :value="lang.id">
              {{ lang.code }}
            </option>
          </select>
        </div>
        <div class="d-flex w-100 mt-3 justify-content-end gap-2">
          <button class="btn btn-outline" @click="showSettings = false">
            Cancel
          </button>
          <button
            :disabled="loading"
            @click="changeLanguage"
            class="btn btn-primary"
          >
            <span>Apply</span>
          </button>
        </div>
      </div>
    </div>
    <ul class="d-flex gap-4 align-items-center">
      <li>
        <button class="btn p-0 m-0" @click="showSettings = !showSettings">
          <img src="/translate.svg" alt="">
        </button>
      </li>
      <li>
        <a
          href="#"
          id="dark-mode-toggle"
          @click="toggleDarkMode"
          :class="{ 'dark-mode-toggle active': true, active: isDarkMode }"
        >
          <i class="ti" :class="[isDarkMode ? 'ti-sun' : 'ti-moon']"></i>
        </a>
      </li>
      <li>
        <div class="dropdown">
          <a href="#" class="avatar avatar-md" data-bs-toggle="dropdown">
            <img :src="user.avatar" alt="img" class="rounded-circle" />
          </a>
          <div class="dropdown-menu dropdown-menu-end p-3">
            <!-- <button
              class="dropdown-item d-flex align-items-center gap-2"
              @click="showSettings = !showSettings"
            >
              <i class="ti ti-settings"></i><span>Language Settings</span>
            </button> -->
            <router-link to="/" class="dropdown-item"
              ><i class="ti ti-logout-2 me-2"></i>Logout</router-link
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>




